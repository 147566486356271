export const bootstrap4Themes = [
	'bootstrap4-light-blue',
	'bootstrap4-light-purple',
	'bootstrap4-dark-blue',
	'bootstrap4-dark-purple',
];
export const mdThemes = [
	'md-light-indigo',
	'md-light-deeppurple',
	'mdc-light-indigo',
	'mdc-light-deeppurple',
	'md-dark-indigo',
	'md-dark-deeppurple',
	'mdc-dark-indigo',
	'mdc-dark-deeppurple',
];
export const laraThemes = [
	'lara-light-blue',
	'lara-light-indigo',
	'lara-light-purple',
	'lara-light-teal',

	'lara-dark-blue',
	'lara-dark-indigo',
	'lara-dark-purple',
	'lara-dark-teal',
];
export const sagaThemes = ['saga-blue', 'saga-green', 'saga-orange', 'saga-purple'];

export const velaThemes = ['vela-blue', 'vela-green', 'vela-orange', 'vela-purple'];

export const aryaThemes = ['arya-blue', 'arya-green', 'arya-orange', 'arya-purple'];

export const sohoThemes = ['soho-light', 'soho-dark'];

export const vivaThemes = ['viva-light', 'viva-dark'];

export const otherThemes = ['tailwind-light', 'fluent-light', 'mira', 'nano'];

export const getThemeType = (theme: string): 'light' | 'dark' =>
	theme.includes('dark') || theme.includes('arya') || theme.includes('vela') ? 'dark' : 'light';
