'use client';

import React, { FC, useRef, useState } from 'react';

import classNames from 'classnames';

import {
	aryaThemes,
	bootstrap4Themes,
	getThemeType,
	laraThemes,
	mdThemes,
	otherThemes,
	sagaThemes,
	sohoThemes,
	velaThemes,
	vivaThemes,
} from '@/src/modules/common/components/ThemeSwitcher/themes';
import { useTheme } from '@/src/modules/common/hooks/useTheme2';
import { CommonStyledComponent } from '@/src/modules/common/types/common.types';
import { Button } from 'primereact/button';
import { MenuItem, MenuItemCommandEvent } from 'primereact/menuitem';
import { TieredMenu } from 'primereact/tieredmenu';
import { Tooltip } from 'primereact/tooltip';

export type ThemeSwitcherProps = {
	tipToDark?: string;
	tipToLight?: string;
	tipToSelect?: string;
	themeFromCookie?: string;
} & CommonStyledComponent;

export const ThemeSwitcher: FC<ThemeSwitcherProps> = ({
	className,
	// tipToDark,
	// tipToLight,
	tipToSelect,
	themeFromCookie,
}) => {
	const { setTheme } = useTheme();

	const [selectedTheme, setSelectedTheme] = useState<string>();

	const menu = useRef<TieredMenu>(null);

	const command = (event: MenuItemCommandEvent) => {
		setTheme(event.item.label ?? '');
		setSelectedTheme(event.item.label);
	};

	const createThemeItems = (themes: string[]): MenuItem[] =>
		themes.map((theme) => ({
			label: theme,
			icon: getThemeType(theme) === 'dark' ? 'pi pi-moon' : 'pi pi-sun',
			command,
		}));

	const themesItems: MenuItem[] = [
		{
			label: 'bootstrap4',
			items: createThemeItems(bootstrap4Themes),
		},
		{
			label: 'md',
			items: createThemeItems(mdThemes),
		},
		{
			label: 'lara',
			items: createThemeItems(laraThemes),
		},
		{
			label: 'saga',
			items: createThemeItems(sagaThemes),
		},
		{
			label: 'vela',
			items: createThemeItems(velaThemes),
		},
		{
			label: 'arya',
			items: createThemeItems(aryaThemes),
		},
		{
			label: 'soho',
			items: createThemeItems(sohoThemes),
		},
		{
			label: 'viva',
			items: createThemeItems(vivaThemes),
		},
		{
			label: 'other',
			items: createThemeItems(otherThemes),
		},
	];

	return (
		<>
			<Tooltip target=".theme-switcher" />

			{/*<Button*/}
			{/*	text*/}
			{/*	rounded*/}
			{/*	onClick={() => toggle()}*/}
			{/*	size={'small'}*/}
			{/*	icon={themesConfig[theme].icon}*/}
			{/*	className={classNames('text-color theme-switcher', className)}*/}
			{/*	aria-label={'Theme toggle'}*/}
			{/*	data-pr-tooltip={theme === 'light' ? tipToDark : tipToLight}*/}
			{/*	data-pr-position={'bottom'}*/}
			{/*	style={{ marginRight: '-14px' }}*/}
			{/*/>*/}

			<TieredMenu
				pt={{ menuitem: { className: 'white-space-nowrap' } }}
				model={themesItems}
				popup
				ref={menu}
				id="popup_menu_left"
			/>

			<Button
				text
				rounded
				size="small"
				icon="pi pi-palette"
				className={classNames('text-color theme-switcher', className)}
				aria-label="Theme toggle"
				data-pr-tooltip={`${tipToSelect} ${selectedTheme ?? themeFromCookie}`}
				data-pr-position="bottom"
				onClick={(event) => menu.current?.toggle(event)}
				aria-controls="popup_menu_left"
				aria-haspopup
				style={{ marginRight: '-14px' }}
			/>
		</>
	);
};
