'use client';

import { getThemeType } from '@/src/modules/common/components/ThemeSwitcher/themes';
import { setCookie } from '@/src/modules/common/utils';

const THEME_LOCAL_STORAGE_KEY = 'jugru.org.theme';

const loadTheme = (href: string) => {
	const link = document.getElementById(APP_THEME_LINK_ID) as HTMLLinkElement;
	const html = document.getElementsByTagName('html')?.[0];

	if (!link || !html) {
		return;
	}

	link.href = href;

	const htmlThemeAttribute = getThemeType(href);

	html.removeAttribute('light');
	html.removeAttribute('dark');
	html.setAttribute(htmlThemeAttribute, '');
};

export const APP_THEME_LINK_ID = 'theme-link';

export type UseTheme = {
	setTheme: (theme: string) => void;
};

export const useTheme = (): UseTheme => {
	const setTheme = (theme: string) => {
		const href = `/themes/${theme}/theme.css`;

		loadTheme(href);

		const cookieValue = `${theme}; path=/; max-age=${1000 * 60 * 60 * 24 * 365}`;

		setCookie(THEME_LOCAL_STORAGE_KEY, cookieValue);
	};

	return {
		setTheme,
	};
};
