import classNames from 'classnames';

import styles from './sidebar.module.scss';

import { routes, routesParts, urlWithId } from '@/src/modules/common/routes/common.routes';
import { Routes } from '@/src/modules/common/routes/common.routes.types';
import { Link } from '@/src/modules/common/utils';
import { Locale } from '@/src/modules/i18n';
import { useTranslations } from 'next-intl';
import { MenuItem, MenuItemOptions } from 'primereact/menuitem';

import ChevronDownIcon from '../../../../icons/ChevronDownIcon';
import ChevronUpIcon from '../../../../icons/ChevronUpIcon';

export const EVENTS_PARENT_ITEM_ID = 'events';
export const DASHBOARD_PARENT_ITEM_ID = 'dashboard';
export const USERS_PARENT_ITEM_ID = 'users';

type ExtendedMenuItem = MenuItem & {
	ii?: boolean;
	iii?: boolean;
	iv?: boolean;
	locale?: Locale;
};

const itemRenderer = (item: ExtendedMenuItem, options: MenuItemOptions) => {
	const { active } = options as MenuItemOptions & { active: boolean };

	return (
		<Link
			className={classNames(
				styles.link,
				'px-3 py-2 cursor-pointer',
				item.ii && styles.ii,
				item.iii && styles.iii,
				item.iv && styles.iv,
			)}
			// onClick={options.onClick}
			locale={item.locale}
			href={item.url ?? '#'}
		>
			<span className={`${item.icon}`} />
			<span className={`mx-2`}>{item.label}</span>

			{/*{item.id?.startsWith('season-') && (*/}
			{/*	<Badge severity={'info'} className="" value={item.items?.length ?? 0} />*/}
			{/*)}*/}

			{item.items && item.items.length > 0 && (
				<span
					className={classNames('ml-auto', item.iii && 'opacity-30')}
					style={{ marginBottom: '-3px' }}
				>
					{active ? <ChevronUpIcon /> : <ChevronDownIcon />}
				</span>
			)}

			{/*{item.id === EVENTS_PARENT_ITEM_ID && (!item.items || item.items.length === 0) && (*/}
			{/*	<span className={classNames('ml-auto')}>*/}
			{/*		<i className={'pi pi-spin pi-spinner'} />*/}
			{/*	</span>*/}
			{/*)}*/}
		</Link>
	);
};

export const createDashboardItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceId?: string,
): MenuItem => ({
	id: DASHBOARD_PARENT_ITEM_ID,
	label: t('items.dashboard'),
	icon: 'pi pi-chart-line',
	url: urlWithId(routes[Routes.Dashboard].path, routesParts.workspace, workspaceId),
	disabled: !workspaceId,
	template: itemRenderer,
});

export const createEventsItem = (
	t: ReturnType<typeof useTranslations>,
	workspaceId?: string,
): MenuItem => ({
	id: EVENTS_PARENT_ITEM_ID,
	label: t('items.events'),
	icon: 'pi pi-check-square',
	url: urlWithId(routes[Routes.WorkspaceEvents].path, routesParts.workspace, workspaceId),
	template: itemRenderer,
	disabled: !workspaceId,
});

export const createUsersItem = (
	t: ReturnType<typeof useTranslations>,
	disabled: boolean,
	workspaceId?: string,
): MenuItem => ({
	id: USERS_PARENT_ITEM_ID,
	label: t('items.users'),
	icon: 'pi pi-users',
	url: urlWithId(routes[Routes.WorkspaceUsers].path, routesParts.workspace, workspaceId),
	template: itemRenderer,
	disabled,
});

// export const createEventsMenuItems = (
// 	eventsMap: Map<SeasonId | undefined, CoreApiEventEntity[]>,
// 	seasonsMap: Map<SeasonId, EventSeasonEntity>,
// 	t: ReturnType<typeof useTranslations>,
// 	locale: Locale | string,
// ) => {
// 	const eventsData: ExtendedMenuItem[] = [];
//
// 	for (const [seasonId, eventsArray] of eventsMap) {
// 		const label =
// 			seasonId === undefined
// 				? 'N/A'
// 				: localizeFrom(seasonsMap.get(seasonId)?.name, locale, 'N/A');
//
// 		eventsData.push({
// 			id: `season-${seasonId?.toString() ?? 'undefined'}`,
// 			label,
// 			icon: '',
// 			ii: true,
// 			template: itemRenderer,
// 			items: eventsArray.map((event) => ({
// 				id: `event-${event.id}`,
// 				label: localizeFrom(event.name, locale),
// 				template: itemRenderer,
// 				iii: true,
// 				items: [
// 					{
// 						id: `event-${event.id}-action-status`,
// 						label: t('status'),
// 						icon: 'pi pi-home text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventStatus].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-config`,
// 						label: t('config'),
// 						icon: 'pi pi-check-square text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventConfig].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-activities`,
// 						label: t('activities'),
// 						icon: 'pi pi-list text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventActivities].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-team`,
// 						label: t('team'),
// 						icon: 'pi pi-users text-xs',
// 						url: urlWithId(routes[Routes.EventTeam].path, routesParts.event, event.id ?? ''),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-integrations`,
// 						label: t('integrations'),
// 						icon: 'pi pi-play text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventIntegrations].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-schedule`,
// 						label: t('schedule'),
// 						icon: 'pi pi-th-large text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventSchedule].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-design`,
// 						label: t('design'),
// 						icon: 'pi pi-thumbs-up-fill text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventDesign].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 					{
// 						id: `event-${event.id}-action-tickets`,
// 						label: t('tickets'),
// 						icon: 'pi pi-percentage text-xs',
// 						url: urlWithId(
// 							routes[Routes.EventTickets].path,
// 							routesParts.event,
// 							event.id ?? '',
// 						),
// 						iv: true,
// 						locale,
// 						template: itemRenderer,
// 					},
// 				],
// 			})),
// 		});
// 	}
//
// 	return eventsData;
// };
