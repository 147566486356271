'use client';

import { FC, useEffect } from 'react';

import { AuthVerifyService } from '@/src/modules/auth/services/authVerify.service';

export const NUMBER_OF_VERIFICATION_ATTEMPTS = 1;

export type TryVerifyAuthProps = {
	serverRawToken?: string;
};

export const TryVerifyAuth: FC<TryVerifyAuthProps> = ({ serverRawToken }) => {
	useEffect(() => {
		(async () => {
			const service = new AuthVerifyService(serverRawToken);
			const isCorrectLogged = service.isCorrectLogged();

			if (isCorrectLogged) {
				return;
			}

			let result = false;

			for (let i = 0; i < NUMBER_OF_VERIFICATION_ATTEMPTS; i++) {
				result = await service.refreshAuthData();

				if (result) {
					break;
				}
			}

			if (!result) {
				return;
			}

			window.location.reload();
		})();
	}, [serverRawToken]);

	return null;
};
